import React from "react"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { FaFacebook, FaGithub, FaLinkedin } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  const iconClassName =
    "w-16 h-16 inline-block mr-3 hover:text-black duration-300 transition-all ease-in-out"

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="All posts" />
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="w-full md:w-3/12 pb-8 md:pb-0 m-auto">
              <Img
                fluid={data.avatar.childImageSharp.fluid}
                alt="Felipe Galvão"
                className="rounded-full shadow-md"
              />
            </div>
          </div>
          <div className="w-full mt-12">
            <div className="w-full md:w-6/12 pb-8 md:pb-0 m-auto">
              <p>
                Olá! Meu nome é Felipe Galvão. Sou um engenheiro de software
                brasileiro que decidiu escrever um pouco sobre as coisas que eu
                estudo. Não sei, mas talvez posssa ajudar alguém, ou pelo menos
                eu espero. Feedbacks, sugestões e correções são mais do que bem
                vindos, então me manda uma mensagem aí se tiver alguma delas.
                Você pode me achar através dos links abaixo. Abraços!
              </p>
            </div>
          </div>

          <div className="w-full mt-12">
            <div className="w-full md:w-4/12 pb-8 md:pb-0 m-auto text-center">
              <a
                href="https://www.facebook.com/pribellafronte"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className={iconClassName + " text-blue-700"} />
              </a>
              <a
                href="https://github.com/felipegalvao/"
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub className={iconClassName + " text-gray-700"} />
              </a>
              <a
                href="https://br.linkedin.com/in/felipegalvao1"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin className={iconClassName + " text-blue-600"} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic-felipe.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default About
